jQuery(document).ready ($) ->

  $window = $ window
  $document = $ document

  app =
    step: 0
    breakpoints:
      xs: 480
      sm: 768
      md: 992
      lg: 1200
    onResize:     $.Callbacks()
    onScroll:     $.Callbacks()
    onStepChange: $.Callbacks()
    onEnd:        $.Callbacks()
    isOnepage: $('body').hasClass 'page-onepage'
    isMobile: (/Android|iPhone|iPad|iPod|BlackBerry/i)
      .test(navigator.userAgent || navigator.vendor || window.opera)

  # Scroll page utility
  $.extend
    scrollPage: (scrollTop, callback) ->
      scrollTop = scrollTop || 0
      $ 'html, body'
        .animate { scrollTop: scrollTop }, 'slow', callback

  # SVG alternative
  if !Modernizr.svg
    $ 'img[src$=".svg"]'
    .each ->
      $this = $ @
      src = $this.attr('src').replace '.svg', '.png'
      $this.attr 'src', src

  # "Scroll to top" buttons
  $ '.scrolltop'
    .click (e) ->
      e.preventDefault()
      $.scrollPage()

  # Baseline animation
  do ->

    return if !app.isOnepage
    return if Modernizr.touch

    $stage = $ '#baseline'
    $tp    = $ '#el-top'
    $pl    = $ '#el-pl'
    $d     = $ '#el-d'
    $pr    = $ '#el-pr'
    $n21   = $ '#el-n21'
    $n22   = $ '#el-n22'
    $n3    = $ '#el-n3'
    $a     = $ '#el-a'
    $n3    = $ '#el-n3'
    $t2    = $ '#el-t2'
    $e     = $ '#el-e'

    tl = new TimelineMax delay: 2

    tl
    .add [
      TweenMax.from $stage, 5, { left: '+=50', opacity: 0, ease: Expo.easeOut }
      TweenMax.from $tp, 5,
        { left: '+=50', opacity: 0, ease: Expo.easeOut, delay: 1 }
    ]
    .fromTo [ $pl, $d, $pr ], 2,
      { scale: 0.2, opacity: 0 },
      { scale: 1, opacity: 1, ease: Back.easeOut, delay: 2 }
    .add [
      TweenMax.to $n21, 1, { left: '-=5' }
      TweenMax.to $n22, 1, { top: '+=15', left: '+=15' }
    ], '-=2'
    .staggerTo [ $a, $n3, $t2, $e ], 2, { left: '+=15' }, 0.2, '-=2'
    .duration 3

  # Video
  # $f == froogaloop
  # @link http://developer.vimeo.com/player/embedding
  do ($iframe = $ '#video-iframe') ->

    return if $iframe.size() is 0

    iframe = $iframe[0]
    $player = $f iframe
    $button = $ '#video-play'

    $player.addEvent 'ready', (id) ->

      $button.click (e) ->
        e.preventDefault()
        $button
          .prop 'disabled', true
          .addClass 'disabled'
        $player.api 'play'

      $player.addEvent 'finish', (id) ->
        $button
          .prop 'disabled', false
          .removeClass 'disabled'

  # Parallax / Animations
  do ->

    return if !app.isOnepage

    controller = new ScrollMagic()

    ## Visuel Header
    do ->
      $home = $ '#accueil, #home'
      $bg = $ '#header-bg'
      new ScrollScene
        triggerElement: $home
        duration: 450
        offset: 0
      .addTo controller
      .triggerHook 'onLeave'
      .setTween new TimelineMax().add [
        TweenMax.to $bg, 1,
          top: 300
          ease: Quad.easeOut
      ]

    ## Section "A propos"
    do ->
      $about = $ '#a-propos, #about'
      $layer = $ '#about-layer'
      new ScrollScene
        triggerElement: $about
        duration: $window.height()
        offset: -250
      .addTo controller
      .triggerHook 'onCenter'
      .setTween new TimelineMax().add [
          TweenMax.to $about, 1,
            backgroundPosition: '0 -200px'
            ease: Linear.easeNone
        ,
          TweenMax.to $layer, 1,
            bottom: -100
            ease: Linear.easeNone
      ]

    ## Nombres incrémentés
    do ->
      numberController = new ScrollMagic()
      $ '#number-1, #number-2'
        .each (i, el) ->
          $this = $ @
          min = 0
          max = parseInt $this.text(), 10
          counter =
            var: min
          $this.text min
          tween = TweenMax.to counter, 2,
            var: max,
            delay: i * 1
            onStart: ->
              $this.text min
            onUpdate: ->
              $this.text Math.ceil counter.var
            ease: Power1.easeInOut

          new ScrollScene
            triggerElement: $this
            offset: -300
            reverse: false
          .addTo numberController
          .triggerHook 'onCenter'
          .setTween new TimelineMax().add tween

    # Parallax nombres
    do ->
      $about = $ '#a-propos, #about'
      new ScrollScene
        triggerElement: $about
        offset: 0
        duration: $window.height() / 1.5
      .addTo controller
      .triggerHook 'onEnter'
      .setTween new TimelineMax().add [
          TweenMax.from '#about-p1', 1,
            top: 250
            ease: Linear.easeNone
        ,
          TweenMax.from '#about-p2', 1,
            top: 200
            ease: Linear.easeNone
      ]

    # Background section blog
    do ->
      $bg1 = $ '#blog'
      return if $bg1.size() is 0
      $bg2 = $ '#references, #brands'
      return if $bg2.size() is 0
      $layer1 = $bg1.find '.layer'
      $layer2 = $bg2.find '.layer'
      new ScrollScene
        triggerElement: $bg1
        duration: 550
        offset: 0
      .addTo controller
      .triggerHook 'onLeave'
      .setTween new TimelineMax().add [
        TweenMax.to $bg1, 1,
          backgroundPosition: 'center 550px'
          ease: Linear.easeNone
      ]
      new ScrollScene
        triggerElement: $bg2
        duration: 550
        offset: -200
      .addTo controller
      .triggerHook 'onCenter'
      .setTween new TimelineMax().add [
        TweenMax.from $bg2, 1,
          backgroundPosition: 'center -' + $window.height() / 2 + 'px'
          ease: Linear.easeNone
        TweenMax.from $layer2, 1,
          backgroundColor: 'rgba(255, 255, 255, 1)'
          ease: Linear.easeNone
      ]

    # Animation expertise
    new ScrollScene
      triggerElement: '#expertise-figure'
      reverse: false
    .addTo controller
    .triggerHook 'onCenter'
    .setTween new TimelineMax().add [
        TweenMax.from '#expertise-caption-1', 1,
          right: '-=30'
          opacity: 0
          ease: Expo.easeOut
      ,
        TweenMax.from '#expertise-caption-2', 1,
          right: '-=30'
          opacity: 0
          delay: 1
          ease: Expo.easeOut
      ,
        TweenMax.from '#expertise-caption-3', 1,
          left: '-=30'
          opacity: 0
          delay: 2
          ease: Expo.easeOut
      ,
        TweenMax.from '#expertise-line-1', 1,
          width: 0
          opacity: 0.5
          ease: Quad.easeOut
      ,
        TweenMax.from '#expertise-line-2', 1,
          width: 0
          opacity: 0.5
          delay: 1
          ease: Quad.easeOut
      ,
        TweenMax.from '#expertise-line-3', 1,
          width: 0
          opacity: 0.5
          delay: 1.5
          ease: Quad.easeOut
    ]

    ## Désactive les animations sur mobiles et tablettes
    app.onResize.add (size) ->
      controller.enabled !Modernizr.touch

  # Menu déroulant
  do ($toggle = $ '#menu-toggle') ->

    active = 'active'
    $menu = $ $toggle.attr 'href'
    $both = $menu.add $toggle

    $toggle.click (e) ->
      e.preventDefault()
      e.stopPropagation()
      $both.toggleClass active

    $document.click (e) ->
      $both.removeClass active

  # Navigation
  do ($menu = $ '#menu') ->

    return if !app.isOnepage

    $item = $menu.find '.item'
    $a = $menu.find 'a'
    active = 'active'

    app.onStepChange.add (step) ->
      $item.removeClass active
      .eq step
      .addClass active

    $a.click (e) ->
      target = @hash
      return if target is ''
      e.preventDefault()
      $this = $ @
      return if $this.parent().hasClass active
      $target = $ target
      return if $target.size() is 0
      offset = $target.offset().top
      $.scrollPage offset

  # Update current step on scroll
  app.onScroll.add (fromTop) ->

    return if !app.isOnepage

    $matches = $('.section').map ->
      if $(@).offset().top < fromTop
        return @

    return if $matches.length is 0

    $section = $matches.last()
    id = $section.attr 'id'

    if history.pushState
      history.pushState null, null, '#' + id

    step = $section.index '.section'

    if step isnt app.step
      app.step = step
      app.onStepChange.fire step

  do ->

    hash = window.location.hash
    section = '.section'

    if hash isnt null
      $section = $ section
        .filter hash
      if $section.size() is 1
        app.step = $section.index section

    app.onStepChange.fire app.step

  # Resize callbacks
  do ->
    didResize = false
    delay = 250

    $window.resize ->
      didResize = true

    getSize = ->
      width: $window.width()
      height: $window.height()

    interval = ->
      if didResize
        didResize = false
        app.onResize.fire getSize()

    setInterval interval, delay

    app.onResize.fire getSize()

  # Scroll callbacks
  do ->
    didScroll = false
    delay = 100

    $window.scroll ->
      didScroll = true

    interval = ->
      if didScroll
        didScroll = false
        app.onScroll.fire $window.scrollTop()

    setInterval interval, delay
    app.onScroll.fire $window.scrollTop()

  # The End
  app.onEnd.fire()
